:root {
  --bg-image: url("./images/light.png");
  --bg-color: #fff;
  --text-color: #000;
}

[data-theme="dark"] {
  --bg-image: url("./images/dark.png");
  --bg-color: #282c34;
  --text-color: #fff;
}

.App {
  text-align: center;
}

.App-header {
  background-image: var(--bg-image);
  background-position: left;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100vw;
  height: 100vh;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: var(--text-color);
  transition: background-position 500ms;

  @media (min-width: 48.0em) {
      background-position: center;
  }
}